/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'BR',
  key: 1,
  newsSectionUrl: null,
  footerText: null,
  footerLinks: [
    { value: 'Cancelar o serviço', href: 'https://home.weezchat.com.br/baja' },
    { value: 'Termos e Condições', href: 'https://home.weezchat.com.br/tyc' },
  ],
  LP: 'http://home.weezchat.com.br/in/63sSGIp9HNx6ttRnSNEU',
  useBothSignUpMethod: true,
  avatarSize: 1200,
  gtm: 'GTM-W83RDWN',
  showAllCities: false,
  completeProfileInputs: ['phoneNumber'],
  RTL: false,
  unsubscribeLink: 'https://home.weezchat.com.br/baja',
  freeSubscription : false,
  home: {
    app: {
      available: false,
      linkGoogle: 'https://play.google.com/store/apps/details?id=com.virgoplay.toolov ',
      linkApple: '',
    },
    facebook: {
      available: false,
      link: 'https://www.facebook.com/Toolov-311556236331989/?modal=admin_todo_tour'
    },
    twitter: {
      available: false,
      link: 'https://twitter.com/Toolov_Ar'
    },
    instagram: {
      available: false,
      link: ''
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://www.dgp-legal.com/en-informations-cookies'
    }
  }
};
